import React from 'react';

import {Dialog, DialogActions, DialogContent, Paper, PaperProps} from '@mui/material';
import {Breakpoint} from '@mui/system';

import {useTranslation} from 'react-i18next';

import ErrorMessage from './ErrorMessage';
import ProgressIndicator from './ProgressIndicator';
import DialogCaption from './Form/DialogCaption';
import {DialogButton} from './Buttons/DialogButton';
import {SxObject} from './Mui/SxPropsUtil';
import {InputFormContext} from './Form/FormContext';
import {PageForm} from './Form';
import {handleModalClose} from './Types/ModalCloseParams';
import {processFormSubmit} from './Form/FormElements/FormProcessSubmit';
import {FormImplProps} from './Form/FormElements/FormImpl';
import Env from 'env';

type PageDialogType = 'panel' | 'confirm' | 'yes-no' | 'cancel-confirm';

export interface PageDialogTitle {
    text?: string;
    ttext?: string; // translated text
    name?: string; // field name
    tname?: string; // translated field name
    value?: string; // field value
    tvalue?: string; // translate field value
}

interface PageDialogProps {
    type: PageDialogType;

    size?: Breakpoint;

    title?: string | PageDialogTitle;
    ttitle?: string;

    fromComp?: boolean;

    errorMessage?: string | null;

    dataLoading?: boolean;

    cancelButton?: boolean;
    cancelButtonLabel?: string;

    onClose?: () => void;
    onCloseFalse?: (p: boolean) => void;
    onCloseNull?: (p: null) => void;

    confirmButton?: boolean;
    submitLabel?: string;
    onConfirm?: () => void;

    contentsForm?: InputFormContext;
    contentsFormProps?: FormImplProps;
    form?: InputFormContext;

    children?: React.ReactNode;
}

class PageDialogState {
    isModal: boolean;
    haveActions: boolean;
    haveDialogContentEl = true; // : boolean
    //dialogContentElSx?: SxObject; // = {p: 0}

    paperProps: Partial<PaperProps>;

    constructor(props: PageDialogProps) {
        // this.isModal = props.type === 'panel' || props.type === 'confirm';
        this.isModal = props.type === 'panel';
        this.haveActions = props.type !== 'panel';

        if (props.contentsForm) {
            this.haveDialogContentEl = true; // false
        }

        this.paperProps = {
            sx: makeDialogPaperProps(props),
        };
    }
}

export default function PageDialog(props: PageDialogProps) {
    const stateRef = React.useRef(new PageDialogState(props));
    const st = stateRef.current;

    const [cancelButtonLabel] = React.useState(UseCancelButtonLabel(props));
    const [confirmButtonLabel] = React.useState(UseConfirmButtonLabel(props));

    const handleDialogClose = React.useCallback((_, reason) => {
        if (reason !== 'backdropClick') {
            handleModalClose(props);
        }
    }, []);

    const handleConfirm = React.useCallback(() => {
        props.onConfirm && props.onConfirm();

        if (props.contentsFormProps) {
            processFormSubmit(props.contentsFormProps!);
        }

        if (st.isModal) {
            handleModalClose(props);
        }
    }, []);

    return (
        <Dialog open={true} onClose={handleDialogClose} PaperComponent={Paper} PaperProps={st.paperProps}>
            <DialogCaption {...props} />

            <DialogContent dividers sx={{py: 2}}>
                <PageDialogContent {...props} />
            </DialogContent>

            {st.haveActions && (
                <DialogActions sx={{py: 2}}>
                    {cancelButtonLabel && (
                        <DialogButton label={cancelButtonLabel} onClick={() => handleModalClose(props)} />
                    )}
                    <DialogButton label={confirmButtonLabel} default onClick={handleConfirm} />
                </DialogActions>
            )}
        </Dialog>
    );
}

function PageDialogContent(props: PageDialogProps) {
    if (props.errorMessage) {
        return <ErrorMessage message={props.errorMessage} />;
    }

    if (props.dataLoading) {
        return <ProgressIndicator title='Loading...' />;
    }

    if (props.form) {
        return (
            <PageForm form={props.form} formContainer='none'>
                {props.children}
            </PageForm>
        );
    }

    return <>{props.children}</>;
}

function UseCancelButtonLabel(props: PageDialogProps) {
    const [t] = useTranslation();

    if (props.type === 'panel' || props.type === 'confirm') {
        return undefined;
    }

    if (props.type === 'yes-no') {
        return `${t('No')}`;
    }

    // return 'Cancel';
    return `${t('Cancel')}`;
}

function UseConfirmButtonLabel(props: PageDialogProps) {
    const [t] = useTranslation();

    if (props.submitLabel) return `${t(props.submitLabel)}`;

    if (props.type === 'panel') {
        return undefined;
    }

    // if (props.type === 'confirm') {
    //     // return 'Close';
    //     return `${t('Close')}`;
    // }

    if (props.type === 'yes-no') {
        return `${t('Yes')}`;
    }

    // return 'Confirm';
    return `${t('Confirm')}`;
}

function getDialogWidth(props: PageDialogProps) {
    // *    sm: 600,
    // *    // medium
    // *    md: 900,
    // *    // large
    // *    lg: 1200,
    // *    // extra-large
    // *    xl: 1536,

    if (Env.isMobile) {
        return '94%';
    }

    if (props.size === 'xs') return 0;
    if (props.size === 'sm') return 600;
    if (props.size === 'md' || props.size === undefined) return 900;
    if (props.size === 'lg') return 1200;
    return 1200;
}

function getDialogHeight(props: PageDialogProps) {
    if (props.fromComp === true) {
        return theme_.dialog.height ?? undefined;
    } else {
        return undefined;
    }
}

function makeDialogPaperProps(props: PageDialogProps): SxObject {
    return {
        ...theme_.dialog.sx(),
        minWidth: getDialogWidth(props),
        height: getDialogHeight(props),
    };
}
