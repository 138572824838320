import React from 'react';

import { Button, Stack, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId, GridRowParams } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import PageContents from "components/PageContents";
import Env from 'env';
import ImgElement from 'tsui/DomElements/ImgElement';
import * as CardsApi from 'api/orders'
import ErrorMessage from 'tsui/ErrorMessage';
import * as Api from "api";
import UserOrdersDetailsDialog from 'dialogs/UserOrdersDetailsDialog';
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

import * as GD from "data/GlobalDispatch"
import session_ from 'api/session';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

export class CardOrdersData {
    orderId: string;
    userId!: string;
    order: string;
    activated: string | boolean;
    orderDate: Date;
    clientEmail: string;
    guiCardDate: Date | undefined; //TODO DATE

    cardType: string;
    paid: boolean;


    constructor(c: CardsApi.ApiCardOrder) {
        this.orderId = c.orderId;
        this.cardType = c.cardType === 'B' ? `${t('Business')}` : `${t('Personal')}`;
        this.order = c.order;
        this.activated = c.activated
        this.guiCardDate = c.guiCardDate
        this.clientEmail = c.clientEmail
        this.orderDate = c.orderDate
        this.userId = c.userId
        this.paid = c.paid


    }
}


export default function UserOrdersPage() {

    const [t] = useTranslation();


    let [orderDetailsId, setOrderDetailsId] = React.useState<string | null>(null);
    let [orderCardType, setOrderCardType] = React.useState<string | null>(null);
    let [orderPaid, setOrderPaid] = React.useState(false);

    const mounted = React.useRef(false);
    const [dataRequested, setDataRequested] = React.useState(false);
    let [error, setError] = React.useState<Error | null>(null);
    const [orders, setOrders] = React.useState<CardOrdersData[] | null>(null);

    const [currentLang, setCurrentLang] = React.useState(session_.lang)

    React.useEffect(() => {
        const langListener = () => {
            setCurrentLang(session_.lang)
            setDataRequested(false)
        }

        GD.pubsub_.addListener(GD.langListenerId, langListener)

        return () => GD.pubsub_.removeListener(GD.langListenerId, langListener)
    }, [])

    const gridColumns = React.useMemo<GridColDef[]>(
        () => Env.isMobile ?

            [
                // { field: 'orderId', headerName: `${t('Order Id')}`, headerAlign: 'center', align: 'center', width: Env.isMobile ? 80 : 120, },

                { field: 'orderId', headerName: `${t('№')}`, headerAlign: 'center', align: 'center', width: 70, },
                // { field: 'cardType', headerName: `${t('Card Type')}`, headerAlign: 'center', align: 'center', width: Env.isMobile ? 120 : 200, },


                // { field: 'paid', headerName: `${t('Paid')}`, headerAlign: 'center', width: Env.isMobile ? 90 : 200 },
                {
                    field: 'paid', type: 'actions', headerName: `${t('Paid')}`, headerAlign: 'center', align: 'center', width: 110, getActions: (params: GridRowParams) => [
                        <GridActionsCellItem icon={params.row.paid === true ? <CheckIcon sx={{ color: 'green' }} /> : <ClearIcon sx={{ color: 'red' }} />} onClick={() => onDetails(params.row)} label={t('Paid')} />
                    ]
                },

                // { field: 'orderDate', type: 'dateTime', headerName: 'Card Date', headerAlign: 'center', flex: 1, valueFormatter: ({ value }) => value && new Date(value).toLocaleString("hy-AM", {hour12: false})},

                {
                    field: 'orderDate', type: 'dateTime', headerName: `${t('Order Date')}`, headerAlign: 'center', align: 'center', width: currentLang === 'am' ? 160 : 145, valueFormatter: params =>
                        // moment(params?.value).format("DD.MM.YYYY hh:mm A"),
                        moment(params?.value).format("DD.MM.YYYY HH:mm"),
                },

                // {
                //     field: 'actions', type: 'actions', headerName: `${t('Details')}`, headerAlign: 'center', align: 'center', width: 145, getActions: (params: GridRowParams) => [
                //         <GridActionsCellItem icon={<InfoIcon />} onClick={() => onDetails(params.row)} label="Details" />
                //     ]
                // }

            ]

            : [
                // { field: 'orderId', headerName: `${t('Order Id')}`, headerAlign: 'center', align: 'center', width: Env.isMobile ? 80 : 120, },
                { field: 'orderId', headerName: `${t('№')}`, headerAlign: 'center', align: 'center', width: 70, },
                { field: 'cardType', headerName: `${t('Card Type')}`, headerAlign: 'center', align: 'center', width: Env.isMobile ? 120 : 200, },
                // { field: 'paid', headerName: `${t('Paid')}`, headerAlign: 'center', width: Env.isMobile ? 90 : 200 },
                {
                    field: 'paid', type: 'actions', headerName: `${t('Paid')}`, headerAlign: 'center', align: 'center', width: 110, getActions: (params: GridRowParams) => [
                        <GridActionsCellItem icon={params.row.paid === true ? <CheckIcon sx={{ color: 'green' }} /> : <ClearIcon sx={{ color: 'red' }} />} onClick={() => onDetails(params.row)} label={t('Paid')} />
                    ]
                },
                // { field: 'orderDate', type: 'dateTime', headerName: 'Card Date', headerAlign: 'center', flex: 1, valueFormatter: ({ value }) => value && new Date(value).toLocaleString("hy-AM", {hour12: false})},

                {
                    // field: 'orderDate', type: 'dateTime', headerName: `${t('Order Date')}`, headerAlign: 'center', align: 'center', width: currentLang ==='am' ? 160 : 145, valueFormatter: params =>
                    field: 'orderDate', type: 'dateTime', headerName: `${t('Order Date')}`, headerAlign: 'center', align: 'center', flex: 1, valueFormatter: params =>
                        // moment(params?.value).format("DD.MM.YYYY hh:mm A"),
                        moment(params?.value).format("DD.MM.YYYY HH:mm"),
                },
                // Env.isMobile ?
                // {
                //     field: 'actions', type: 'actions', headerName: `${t('Details')}`, headerAlign: 'center', align: 'center', width: 145, getActions: (params: GridRowParams) => [
                //         <GridActionsCellItem icon={<InfoIcon />} onClick={() => onDetails(params.row)} label="Details" />
                //     ]
                // }
                // :


                // {
                //     field: 'actions', type: 'actions', headerName: `${t('Details')}`, headerAlign: 'center', align: 'center', flex: 1, getActions: (params: GridRowParams) => [
                //         <GridActionsCellItem icon={<InfoIcon />} onClick={() => onDetails(params.row)} label="Details" />
                //     ]
                // }
            ],
        [currentLang]
    );


    const onDetails = React.useCallback((order: CardOrdersData) => {
        setOrderDetailsId(order.orderId);
        setOrderPaid(order.paid)
        setOrderCardType(order.cardType);
    }, []);

    React.useEffect(() => {
        mounted.current = true;
        if (!dataRequested) {
            Api.requestSession<CardsApi.ApiCardOrder[]>('orders', 'fetch', {}).then(orders => {
                if (mounted.current) {
                    //setCards(cards);
                    let ordersData: CardOrdersData[] = [];
                    for (let c of orders) {
                        ordersData.push(new CardOrdersData(c));
                    }

                    setOrders(ordersData)
                }
            }).catch(setError);
            setDataRequested(true);
            return;
        }
        return () => { mounted.current = false }
    }, [dataRequested]);




    if (error) {
        console.debug(error);
        return <ErrorMessage error={error} />
    }

    if (!orders) {
        return <></>
    }

    return <PageContents title="My Orders" current='myOrders'>
        {/* <Stack direction='column' width='100%' justifyContent='center' alignItems='center'> */}

        <Stack direction='column' height='100%' alignItems='center' spacing={2}>
            <ImgElement sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }} src='images/background.svg' />  {/* svg/png */}

            <Stack direction='row' width='100%' alignItems='center' justifyContent='center'>
                <Typography sx={{ p: 1, mt: 2, fontSize: 'large' }}>{t('Total Orders')}:&nbsp;{orders.length}</Typography>
            </Stack>

            <Box component={Paper} sx={{ width: `${Env.isMobile ? '90%' : '50%'}`, height: '80%', backgroundColor: 'transparent', boxShadow: 'none', background: 'none' }}>

                    <DataGrid
                        // sx={{
                        //     width: '100%',
                        //     backgroundColor: theme_.evocardFeatureBackgroundColor,
                        //     border: `1px solid ${theme_.themeColor}`,
                        //     justifyContent: 'center'
                        // }}
                        sx={[
                            theme_.dialog.sx(),
                            {
                                backgroundColor: '#2C2D31',
                                border: 'none',
                            }

                        ]}
                        columns={gridColumns}
                        rows={orders}
                        autoPageSize={true}
                        // disableSelectionOnClick
                        disableColumnMenu
                        getRowId={(row) => row.orderId}
                        // onRowDoubleClick={(row) => { !Env.isMobile ? onDetails(row.row) : null }}
                        // onRowClick={(row) => { Env.isMobile ? onDetails(row.row) : null }}

                        onRowClick={(row) => {onDetails(row.row)}}
                    />

               
            </Box>
        </Stack>

        {
            orderDetailsId && (
                <UserOrdersDetailsDialog
                    orderId={orderDetailsId}
                    orderPaid={orderPaid}
                    cardType={orderCardType}
                    onClose={() => {
                        setOrderDetailsId(null)
                    }}
                />
            )
        }
        {/* <TableContainer component={Paper}>
                <Table sx={{ width: '100%' }} aria-label='simple table'>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#e6e6e6' }}>
                            <TableCell sx={{ textAlign: 'center' }}>Card type</TableCell>
                            <TableCell sx={{ textAlign: 'center' }} align='right'>
                                Ordered cards quantity
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }} align='right'>
                                Ordered Card price
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }} align='right'>
                                Ordered card Date
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map((order, index) =>
                            <TableRow key={order.orderId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell sx={{ textAlign: 'center' }} component='th' scope='row'>
                                    {order.cardType === 'B' ? 'Business' : 'Personal'}
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }} component='th' scope='row'>
                                    1
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }} align='right'>
                                    {order.cardType === 'B' ? '5000AMD' : '3000AMD'}
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }} align='right'>
                                    {order.orderDate.toString()}
                                </TableCell>

                            </TableRow>
                        )}

                    </TableBody>
                </Table>
            </TableContainer> */}
        {/* </Stack> */}
        {/* <OrderConfirmationDialog show={orderModal} cardPrice={cardPrice} cardType={cardType} onSelected={() => { }} onCloseFalse={setOrderModal} /> */}


    </PageContents >;

}



