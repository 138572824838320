import Env from 'env';

export interface CardCategoryInit {
    id: string;
    categoryName: string;
    cards: CardBoxInit[];
}

export interface CardBoxInit {
    id: string;
    cardName: string;
    cardPrice: string;
    // cardDescription: string;
    name: string;
    compPos: string;
    contact: string;
    socialNetworks: string;
    mapLink?: string;
    compDescription?: string;
    workingSchedule?: string;
    address?: string;
    cardType: string;
    cardTypeFullName: string;
    cardFrontSide: string;
    cardBackSide: string;
    cardId: string;
    // cardFrontImageSrc: string;
    // cardBackImageSrc: string;
}

//const cardCategoryData_ = new Map<string,
export const cardCategoryArray_: CardCategoryInit[] = [];

const cardData_ = new Map<string, CardBoxInit>();
export const cardDataArray_ = new Array<CardBoxInit>();

const cardBoxInit: CardBoxInit[] = [
    {
        id: '1',
        cardName: 'Business Card',
        cardPrice: 'order.business_card_price',
        // cardDescription: 'order.card_description',
        name: 'Name',
        cardId: '1000',
        compPos: 'Company/Position',
        contact: 'Contact',
        socialNetworks: 'Social Networks & Business Web Page Link',
        mapLink: 'Map Link',
        compDescription: 'Company Description',
        workingSchedule: Env.isMobile ? 'contact.opening_hours' : 'Working schedule',
        address: 'Address',
        // cardImageSrc: 'images/card1.svg'
        cardType: 'B',
        cardTypeFullName: 'Business',
        cardFrontSide: 'front',
        cardBackSide: 'back',
    },

    // {
    //     id: '2',
    //     cardName: 'Basic Card',
    //     cardPrice: '2000AMD',
    //     cardDescription: 'Description',
    //     // cardImageSrc: 'images/card1.svg'
    //     cardImageSrc: 'images/basic-card.jpg'
    // },

    {
        id: '2',
        cardName: 'Personal Card',
        cardPrice: 'order.personal_card_price',
        cardId: '1001',
        // cardDescription: 'order.card_description',
        name: 'Name',
        compPos: 'Company/Position',
        contact: 'Contact',
        socialNetworks: 'Social Networks',
        // cardImageSrc: 'images/card1.svg'
        // cardImageSrc: 'images/personal-card.jpg'
        cardType: 'P',
        cardTypeFullName: 'Personal',
        cardFrontSide: 'front',
        cardBackSide: 'back',
    },

    // {
    //     id: '2',
    //     cardName: 'Business Card',
    //     cardPrice: '5000AMD',
    //     cardDescription: 'Description',
    //     // cardImageSrc: 'images/card1.svg'
    //     cardImageSrc: 'images/business-card.jpg'
    // },

    // {
    //     id: '3',
    //     cardName: 'Business Card',
    //     cardPrice: '5000AMD',
    //     cardDescription: 'Description',
    //     // cardImageSrc: 'images/card1.svg'
    //     cardImageSrc: 'images/business-card.jpg'
    // },

    // {
    //     id: '4',
    //     cardName: 'Basic Card',
    //     cardPrice: '2000AMD',
    //     cardDescription: 'Description',
    //     // cardImageSrc: 'images/card2.svg'
    //     cardImageSrc: 'images/basic-card.jpg'

    // },

    // {
    //     id: '5',
    //     cardName: 'Basic Card',
    //     cardPrice: '2000AMD',
    //     cardDescription: 'Description',
    //     // cardImageSrc: 'images/card2.svg'
    //     cardImageSrc: 'images/basic-card.jpg'

    // },

    // {
    //     id: '6',
    //     cardName: 'Basic Card',
    //     cardPrice: '2000AMD',
    //     cardDescription: 'Description',
    //     // cardImageSrc: 'images/card2.svg'
    //     cardImageSrc: 'images/basic-card.jpg'

    // },

    // {
    //     id: '7',
    //     cardName: 'Personal Card',
    //     cardPrice: '3000AMD',
    //     cardDescription: 'Description',
    //     // cardImageSrc: 'images/card1.svg'
    //     cardImageSrc: 'images/personal-card.jpg'
    // },

    // {
    //     id: '8',
    //     cardName: 'Personal Card',
    //     cardPrice: '3000AMD',
    //     cardDescription: 'Description',
    //     // cardImageSrc: 'images/card1.svg'
    //     cardImageSrc: 'images/personal-card.jpg'
    // },

    // {
    //     id: '9',
    //     cardName: 'Personal Card',
    //     cardPrice: '3000AMD',
    //     cardDescription: 'Description',
    //     // cardImageSrc: 'images/card1.svg'
    //     cardImageSrc: 'images/personal-card.jpg'
    // },
];

for (let item of cardBoxInit) {
    cardData_.set(item.id, item);
    cardDataArray_.push(item);
}

const cardCategoryInit: CardCategoryInit[] = [
    {
        id: '1',
        categoryName: 'Premium Cards',
        cards: cardDataArray_,
    },

    // {
    //     id: "2",
    //     categoryName: 'Business Cards',
    //     cards: cardDataArray_,
    // },

    // {
    //     id: "3",
    //     categoryName: 'Basic Cards',
    //     cards: cardDataArray_,
    // },
];

for (let item of cardCategoryInit) {
    cardCategoryArray_.push(item);
}

/*
function addCardBox(props: CardBoxInit) {
    let card: any = { ...props };
    cardData_.set(props.id, card);

    cardDataArray_.push(card);
}

addCardBox({
    id: '1',
    cardName: 'Blue Business Card',
    cardPrice: '2000AMD',
    cardDescription: 'Description'

})
addCardBox({
    id: '2',
    cardName: 'Blue Business Card',
    cardPrice: '2000AMD',
    cardDescription: 'Description'
})
addCardBox({
    id: '3',
    cardName: 'Blue Business Card',
    cardPrice: '2000AMD',
    cardDescription: 'Description'
})

addCardBox({
    id: '4',
    cardName: 'Gold Business Card',
    cardPrice: '2000AMD',
    cardDescription: 'Description'
})

addCardBox({
    id: '5',
    cardName: 'Gold Business Card',
    cardPrice: '2000AMD',
    cardDescription: 'Description'
})

addCardBox({
    id: '6',
    cardName: 'Gold Business Card',
    cardPrice: '2000AMD',
    cardDescription: 'Description'
})
*/
