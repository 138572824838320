import React from 'react';

import {useNavigate, useLocation} from 'react-router-dom';

import {Dialog, Typography} from '@mui/material';

import * as Api from 'api';

import PageContents from 'components/PageContents';

import {ErrorAlert} from 'components/ErrorAlert';
import PageDialog from '../tsui/PageDialog';
import { alertObject } from '../tsui/AlertObject';
import ProgressIndicator from '../tsui/ProgressIndicator';

export default function AwsConfirmUserPage() {
    const navigate = useNavigate();

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [confirmRequested, setConfirmRequested] = React.useState(false);
    const [confirmed, setConfirmed] = React.useState(false);

    const onClose = React.useCallback(() => {
        //if (reason == 'backdropClick' || reason == 'escapeKeyDown') return;
        setConfirmed(false);
        navigate('/login');
    }, []);

    React.useEffect(() => {
        if (confirmRequested) return;

        const awsClientId = urlParams.get('client_id')!;
        const awsUserName = urlParams.get('user_name')!;
        const awsConfirmationCode = urlParams.get('confirmation_code')!;

        setConfirmRequested(true);

        Api.requestPublic('auth', 'join_aws_confirm_email', {
            client_id: awsClientId,
            user_name: awsUserName,
            confirmation_code: awsConfirmationCode,
        })
            .then((res) => {
                setConfirmed(true);
            })
            .catch(alertObject);
    }, []);

    return <PageContents title="Registration">
        {confirmed ?
            <PageDialog type='panel' size='sm' title='Registration' onClose={onClose}>
                <Typography sx={{textAlign: 'center' ,fontSize: 'xx-large' }}>Successfully confirmed!</Typography>
            </PageDialog>
            :
            <ProgressIndicator/>
        }
    </PageContents>

}
